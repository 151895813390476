import { Inject, Injectable } from '@angular/core';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { EncrDecrService } from 'src/app/services/encr-decr-service.service';
import { Settings } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  decrypted: any;
  id: any;

  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private EncrDecr: EncrDecrService) {
    if (localStorage.getItem('log')) {
      this.decrypted = this.EncrDecr.get(
        '123456$#@$^@1ERF',
        localStorage.getItem('log')
      );
      this.id = this.EncrDecr.get(
        '123456$#@$^@1ERF',
        localStorage.getItem('keylog')
      );
    }
    Settings.defaultLocale = this.id;
    this.loadNavigation();
  }

  loadNavigation(): void {
    if (this.decrypted == 0) {
      //'Administrador'
      this._items.next([
        {
          type: 'subheading',
          label: 'Dashboards',
          children: [
            {
              type: 'link',
              label: 'Analytics',
              route: '/',
              icon: 'mat:insights',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            {
              type: 'link',
              label: 'Empleados',
              route: '/apps/empleados',
              icon: 'mat:group'
            },
            {
              type: 'link',
              label: 'Imprentas',
              route: '/apps/imprentas',
              icon: 'mat:store' //print
            },
            {
              type: 'link',
              label: 'Ordenes',
              route: '/apps/ordenes',
              icon: 'mat:computer' //print credit_card
            },
            {
              type: 'link',
              label: 'Laminas',
              route: '/apps/laminas',
              icon: 'mat:filter_none' //print
            },
            {
              type: 'link',
              label: 'Almacen',
              route: '/apps/almacen',
              icon: 'mat:how_to_vote'
            },
            {
              type: 'link',
              label: 'Envios',
              route: '/apps/envios',
              icon: 'mat:send'
            },
            {
              type: 'link',
              label: 'Pagos',
              route: '/apps/pagos',
              icon: 'mat:monetization_on' //print
            },




            {
              type: 'link',
              label: 'Deposito',
              route: '/apps/deposito',
              icon: 'mat:store' 
            },
            // {
            //   type: 'link',
            //   label: 'Post Servicio',
            //   route: '/apps/post-service',
            //   icon: 'mat:call_to_action' 
            // },


            

            {
              type: 'dropdown',
              label: 'Areas',
              icon: 'mat:people',
              children: [
                {
                  type: 'link',
                  label: 'Monitoreo',
                  route: '/apps/areas/monitoreo'
                },
                {
                  type: 'link',
                  label: 'Armado',
                  route: '/apps/areas/armado'
                },
                {
                  type: 'link',
                  label: 'Prensado',
                  route: '/apps/areas/prensista'
                },
                {
                  type: 'link',
                  label: 'Barniz - Plastico',
                  route: '/apps/areas/acabado/barniz-plastico'
                },
                {
                  type: 'link',
                  label: 'Corte',
                  route: '/apps/areas/acabado/corte'
                }
              ]
            },
            {
              type: 'dropdown',
              label: 'Registros',
              icon: 'mat:receipt',
              children: [
                {
                  type: 'link',
                  label: 'Mis Instrucciones',
                  route: '/apps/registros/instrucciones',
                },
                {
                  type: 'link',
                  label: 'Tarjeta tamaño y costo',
                  route: '/apps/registros/card-size-cost',
                },
                {
                  type: 'link',
                  label: 'Destinos',
                  route: '/apps/registros/destinos',
                  icon: 'mat:local_shipping' //print
                },
              ]
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Gestion',
          children: [
            {
              type: 'dropdown',
              label: 'Inventario',
              icon: 'mat:assignment',
              children: [
                {
                  type: 'link',
                  label: 'Productos',
                  route: '/gestion/inventario/productos'
                },
                {
                  type: 'link',
                  label: 'Entradas',
                  route: '/gestion/inventario/entradas'
                },
                {
                  type: 'link',
                  label: 'Salidas',
                  route: '/gestion/inventario/salidas'
                },
                // {
                //   type: 'link',
                //   label: 'Ventas',
                //   route: '/gestion/inventario/ventas',
                // },
              ]
            },
            {
              type: 'dropdown',
              label: 'Reportes',
              icon: 'mat:people',
              children: [
                {
                  type: 'link',
                  label: 'Ordenes',
                  route: '/gestion/reports/report-ordenes'
                },
                {
                  type: 'link',
                  label: 'Tiempos de produccion',
                  route: '/gestion/reports/report-tiempo-produccion'
                }
              ]
            },
          ]
        },
      ]);
    } else if (this.decrypted == 1) {
      //'Recepcion'
      this._items.next([
        {
          type: 'subheading',
          label: 'Dashboards',
          children: [
            {
              type: 'link',
              label: 'Analytics',
              route: '/',
              icon: 'mat:insights',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            {
              type: 'link',
              label: 'Imprentas',
              route: '/apps/imprentas',
              icon: 'mat:store' //print
            },
            {
              type: 'link',
              label: 'Ordenes',
              route: '/apps/ordenes',
              icon: 'mat:computer' //print credit_card
            },
            {
              type: 'link',
              label: 'Laminas',
              route: '/apps/laminas',
              icon: 'mat:filter_none' //print
            },
            {
              type: 'link',
              label: 'Almacen',
              route: '/apps/almacen',
              icon: 'mat:how_to_vote' //print
            },
            {
              type: 'link',
              label: 'Pagos',
              route: '/apps/pagos',
              icon: 'mat:monetization_on' //print
            }
          ]
        }
      ]);
    } else if (this.decrypted == 5) {
      //'Armado'
      this._items.next([
        {
          type: 'subheading',
          label: 'Dashboards',
          children: [
            {
              type: 'link',
              label: 'Analytics',
              route: '/',
              icon: 'mat:insights',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            {
              type: 'dropdown',
              label: 'Areas',
              icon: 'mat:people',
              children: [
                {
                  type: 'link',
                  label: 'Armado',
                  route: '/apps/areas/armado'
                }
              ]
            },
            {
              type: 'link',
              label: 'Laminas',
              route: '/apps/laminas',
              icon: 'mat:filter_none',
            }
          ]
        }
      ]);
    } else if (this.decrypted == 2) {
      //'Prensa'
      this._items.next([
        {
          type: 'subheading',
          label: 'Dashboards',
          children: [
            {
              type: 'link',
              label: 'Analytics',
              route: '/',
              icon: 'mat:insights',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            {
              type: 'dropdown',
              label: 'Areas',
              icon: 'mat:people',
              children: [
                {
                  type: 'link',
                  label: 'Prensado',
                  route: '/apps/areas/prensista'
                }
              ]
            }
          ]
        }
      ]);
    } else if (this.decrypted == 3) {
      //'Acabado'
      this._items.next([
        {
          type: 'subheading',
          label: 'Dashboards',
          children: [
            {
              type: 'link',
              label: 'Analytics',
              route: '/',
              icon: 'mat:insights',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            {
              type: 'dropdown',
              label: 'Areas',
              icon: 'mat:people',
              children: [
                {
                  type: 'link',
                  label: 'Barniz - Plastico',
                  route: '/apps/areas/acabado/barniz-plastico'
                },
                {
                  type: 'link',
                  label: 'Corte',
                  route: '/apps/areas/acabado/corte'
                }
              ]
            }
          ]
        }
      ]);
    } else if (this.decrypted == 4) {
      //'Envios'
      this._items.next([
        {
          type: 'subheading',
          label: 'Dashboards',
          children: [
            {
              type: 'link',
              label: 'Analytics',
              route: '/',
              icon: 'mat:insights',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            {
              type: 'link',
              label: 'Envios',
              route: '/apps/envios',
              icon: 'mat:send'
            },
            {
              type: 'link',
              label: 'Pagos',
              route: '/apps/pagos',
              icon: 'mat:monetization_on' //print
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Gestion',
          children: [
            {
              type: 'dropdown',
              label: 'Inventario',
              icon: 'mat:assignment',
              children: [
                {
                  type: 'link',
                  label: 'Productos',
                  route: '/gestion/inventario/productos'
                }
              ]
            }
          ]
        }
      ]);
    } else if (this.decrypted == 6) {
      // CTP
      this._items.next([
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            {
              type: 'link',
              label: 'Laminas',
              route: '/apps/laminas',
              icon: 'mat:filter_none',
              routerLinkActiveOptions: { exact: true }
            },
          ]
        },
      ]);
    }
  }
}
